<template>
  <div>
    <bfc-bar></bfc-bar>
    <admin-menu></admin-menu>
    <v-card
      ><v-card-title>Setup Exchange Rate</v-card-title>
      <v-card-text
        ><form>
          <v-text-field v-model="exchangeRate" label="Bath/USD" required></v-text-field>
        </form>
      </v-card-text>
      <v-card-actions><v-btn primary @click="save">Save</v-btn></v-card-actions></v-card
    >
  </div>
</template>
<script>
//import { defineComponent } from "@vue/composition-api";
import SystemBar from "../../library/system-bar/system-bar";
import AdminMenu from "../menubar/menubar";
import axios from "axios";
export default {
  components: {
    "bfc-bar": SystemBar,
    "admin-menu": AdminMenu,
  },
  data() {
    return { exchangeRate: "", url: process.env.VUE_APP_LOYALTY_API_URI };
  },
  mounted() {
    this.initial();
  },
  methods: {
    async save() {
      try {
        //
        await axios
          .put(
            this.url + "/v1/admin/updateConfig",
            {
              cfg_code: "EXRATE",
              cfg_name: "EXCHANGE_RATE",
              cfg_value: this.exchangeRate,
            }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.exchangeRate = response.data[0].cfg_value;
          });
      } catch (err) {
        console.log(err);
      }
    },
    async initial() {
      try {
        //
        await axios
          .post(
            this.url + "/v1/admin/getConfigByName",
            {
              code: "EXRATE",
              name: "EXCHANGE_RATE",
            }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.exchangeRate = response.data[0].cfg_value;
          });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style scoped></style>
